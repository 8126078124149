import { Container, Row, Col } from 'react-bootstrap';
import { useGlobalContext } from '../../context';
import { Link, useParams } from 'react-router-dom';
import './Journal.css'
import { useEffect, useState } from 'react';
import Pagination from 'react-bootstrap/Pagination';
import { Button } from 'reactstrap';
import { ArrowLeftCircle } from 'react-feather';

const JournalsDetailUpdate = () => {
    const { isNightMode } = useGlobalContext()
    const [inputValue, setInputValue] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const total = 100; // Replace with your total number of pages
    const maxPagesToShow = 7; // Maximum number of pages to show in the pagination UI
    const { id } = useParams()
    const [selectedJournal, setSelectedJournal] = useState({})
    const [PDFOpen, setPDFOpen] = useState(false)

    useEffect(() => {
        fetch(`https://library-b.ethical-digit.com/research_journals/${id}`)
            // fetch(`https://library-b.ethical-digit.com/books`)
            .then(response => response.json())
            .then(res => {
                console.log(res)
                setSelectedJournal(res?.researchJournal)
            })
            .catch(error => console.error(error));
    }, [])

    console.log(selectedJournal)

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
        window.scrollTo(0, 0);
        console.log(pageNumber)
        // Do something with the selected page number
    };
    const renderPageItem = (pageNumber) => (
        <Pagination.Item
            key={pageNumber}
            active={pageNumber === currentPage}
            onClick={() => handlePageClick(pageNumber)}
        >
            {pageNumber}
        </Pagination.Item>
    );

    const renderEllipsisItem = () => (
        <Pagination.Ellipsis key="ellipsis" disabled />
    );
    const paginationItems = [];
    if (total <= maxPagesToShow) {
        for (let i = 1; i <= total; i++) {
            paginationItems.push(renderPageItem(i));
        }
    } else {
        const leftPagesToShow = Math.ceil(maxPagesToShow / 2) - 1;
        const rightPagesToShow = maxPagesToShow - leftPagesToShow - 1;
        let firstPageToShow = currentPage - leftPagesToShow;
        let lastPageToShow = currentPage + rightPagesToShow;

        if (firstPageToShow < 1) {
            lastPageToShow += Math.abs(firstPageToShow) + 1;
            firstPageToShow = 1;
        } else if (lastPageToShow > total) {
            firstPageToShow -= lastPageToShow - total;
            lastPageToShow = total;
        }

        if (firstPageToShow > 1) {
            paginationItems.push(renderPageItem(1));
            if (firstPageToShow > 2) {
                paginationItems.push(renderEllipsisItem());
            }
        }

        for (let i = firstPageToShow; i <= lastPageToShow; i++) {
            paginationItems.push(renderPageItem(i));
        }

        if (lastPageToShow < total) {
            if (lastPageToShow < total - 1) {
                paginationItems.push(renderEllipsisItem());
            }
            paginationItems.push(renderPageItem(total));
        }
    }

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setInputValue('');
    };

    const journals = [
        {
            title: 'Hindrances of International Publication of Egyptian Educational Researches from the Staff Members’ Point of View at Damietta University',
            authors: "Aly S. Gohar, Wael W. Radwan, Hosam I. Morad",
            journal: 'Category 1',
            date: "August 31, 2018",
            volume: '1',
            issue: "11",
            doi: '10.4236/ce.2018.911122',
            downloads: 100,
            views: 200,
            citations: 50,
        },
        {
            title: 'The Role of Branding on Educational Performance in the Egyptian Private Universities',
            authors: "Aly S. Gohar, Wael W. Radwan, Hosam I. Morad",
            journal: 'Category 2',
            date: "August 31, 2018",
            volume: '2',
            issue: "11",
            doi: '10.4236/ce.2018.911122',
            downloads: 150,
            views: 250,
            citations: 75,
        },
        {
            authors: "Aly S. Gohar, Wael W. Radwan, Hosam I. Morad",
            title: "Hindrances of International Publication of Egyptian Educational Researches from the Staff Members’ Point of View at Damietta University",
            journal: "Creative Education",
            volume: "9",
            issue: "11",
            date: "August 31, 2018",
            doi: "10.4236/ce.2018.911122",
            downloads: 708,
            views: 1462,
            citations: 1,
        },
        {
            authors: "Amel Rashwan, Ayman Shawky, Mohamed A. Ragheb, Alaa A. Bary",
            title: "The Role of Branding on Educational Performance in the Egyptian Private Universities",
            journal: "Open Access Library Journal",
            volume: "5",
            issue: "10",
            date: "October 15, 2018",
            doi: "10.4236/oalib.1104882",
            downloads: 699,
            views: 2566,
            citations: 1,
        },
        {
            authors: "Karen E. Boden",
            title: "Pedagogical Innovation among University Faculty",
            journal: "Creative Education",
            volume: "10",
            issue: "5",
            date: "May 14, 2019",
            doi: "10.4236/ce.2019.105063",
            downloads: 860,
            views: 2260,
            citations: 0,
        },
        {
            authors: "Zhiqiang Zhou, Li Guo",
            title: "The Teaching and Learning Status of Chemical Safety in Chinese Universities and Reform Suggestions on It",
            journal: "Creative Education",
            volume: "10",
            issue: "10",
            date: "October 14, 2019",
            doi: "10.4236/ce.2019.1010154",
            downloads: 843,
            views: 1352,
            citations: 0,
        },
        {
            authors: "Qiangqiang Zhou",
            title: "Research on Talent Cultivation Strategy of Adult Universities under the Background of “Mass Entrepreneurship and Innovation”",
            journal: "Creative Education",
            volume: "10",
            issue: "13",
            date: "December 20, 2019",
            doi: "10.4236/ce.2019.1013261",
            downloads: 550,
            views: 1187,
            citations: 0,
        },
        {
            authors: "Jiemin Liang, Xinfeng Zhang, Juan Wang, Liu Feng, Chao Xu, Kun Cheng, Guoguang Cao, Dongmei Yan, Bo Liu",
            title: "Mental Health Status of College Freshmen and Influencing Factors",
            journal: "Psychology",
            volume: "11",
            issue: "5",
            date: "May 28, 2020",
            doi: "10.4236/psych.2020.115050",
            downloads: 530,
            views: 2059,
            citations: 0,
        },
        {
            title: 'Hindrances of International Publication of Egyptian Educational Researches from the Staff Members’ Point of View at Damietta University',
            authors: "Aly S. Gohar, Wael W. Radwan, Hosam I. Morad",
            journal: 'Category 1',
            date: "August 31, 2018",
            volume: '1',
            issue: "11",
            doi: '10.4236/ce.2018.911122',
            downloads: 100,
            views: 200,
            citations: 50,
        },
        {
            title: 'The Role of Branding on Educational Performance in the Egyptian Private Universities',
            authors: "Aly S. Gohar, Wael W. Radwan, Hosam I. Morad",
            journal: 'Category 2',
            date: "August 31, 2018",
            volume: '2',
            issue: "11",
            doi: '10.4236/ce.2018.911122',
            downloads: 150,
            views: 250,
            citations: 75,
        },
        {
            authors: "Aly S. Gohar, Wael W. Radwan, Hosam I. Morad",
            title: "Hindrances of International Publication of Egyptian Educational Researches from the Staff Members’ Point of View at Damietta University",
            journal: "Creative Education",
            volume: "9",
            issue: "11",
            date: "August 31, 2018",
            doi: "10.4236/ce.2018.911122",
            downloads: 708,
            views: 1462,
            citations: 1,
        },
        {
            authors: "Amel Rashwan, Ayman Shawky, Mohamed A. Ragheb, Alaa A. Bary",
            title: "The Role of Branding on Educational Performance in the Egyptian Private Universities",
            journal: "Open Access Library Journal",
            volume: "5",
            issue: "10",
            date: "October 15, 2018",
            doi: "10.4236/oalib.1104882",
            downloads: 699,
            views: 2566,
            citations: 1,
        },
        {
            authors: "Karen E. Boden",
            title: "Pedagogical Innovation among University Faculty",
            journal: "Creative Education",
            volume: "10",
            issue: "5",
            date: "May 14, 2019",
            doi: "10.4236/ce.2019.105063",
            downloads: 860,
            views: 2260,
            citations: 0,
        },
        {
            authors: "Zhiqiang Zhou, Li Guo",
            title: "The Teaching and Learning Status of Chemical Safety in Chinese Universities and Reform Suggestions on It",
            journal: "Creative Education",
            volume: "10",
            issue: "10",
            date: "October 14, 2019",
            doi: "10.4236/ce.2019.1010154",
            downloads: 843,
            views: 1352,
            citations: 0,
        },
        {
            authors: "Qiangqiang Zhou",
            title: "Research on Talent Cultivation Strategy of Adult Universities under the Background of “Mass Entrepreneurship and Innovation”",
            journal: "Creative Education",
            volume: "10",
            issue: "13",
            date: "December 20, 2019",
            doi: "10.4236/ce.2019.1013261",
            downloads: 550,
            views: 1187,
            citations: 0,
        },
        {
            authors: "Jiemin Liang, Xinfeng Zhang, Juan Wang, Liu Feng, Chao Xu, Kun Cheng, Guoguang Cao, Dongmei Yan, Bo Liu",
            title: "Mental Health Status of College Freshmen and Influencing Factors",
            journal: "Psychology",
            volume: "11",
            issue: "5",
            date: "May 28, 2020",
            doi: "10.4236/psych.2020.115050",
            downloads: 530,
            views: 2059,
            citations: 0,
        },
        // Add more journals here
    ];

    return (
        <div className={` ${isNightMode ? 'nightHome journal' : 'lightJournal'}`}>
            {!PDFOpen ? (
                <div className={`${isNightMode ? 'nightJournalDetail' : ''}`} style={{background: '#ECF0F3'}}>
                <Container className={`${isNightMode ? 'nightJournalDetail' : ''} mt-5 pt-5 relative`}>
                    <div className='d-lg-flex gap-3'>
                        {/* <h4 className='mb-4'>Subjects and fields</h4> */}
                        <div className='p-3 rounded mt-md-0 col-md-3 border jounaldetailfixedDiv' style={{ background: '#FFFFFF', height: '400px', }}>
                            <img style={{ width: '100%', height: '300px' }} src={`http://nmdc-library.s3.amazonaws.com/journalPdf/images/${selectedJournal?.coverPhoto}`} alt="" />
                            <Button style={{ width: '100%' }} color='primary' outline className='mt-2' onClick={() => setPDFOpen(true)}>
                                Read PDF
                            </Button>
                            {/* <h5>Browse Subjects</h5>
                        <div className='mb-2' style={{ background: 'red', border: '2px solid red' }}></div> */}
                            {/* <ul className='list-unstyled'>
                            <li>
                                <Link to='#'>Biomedical &amp; Life Sci.</Link>
                            </li>
                            <li>
                                <Link to='#'>Business &amp; Economics</Link>
                            </li>
                            <li>
                                <Link to='#'>Chemistry &amp; Materials Sci.</Link>
                            </li>
                            <li>
                                <Link to='#'>Computer Sci. &amp; Commun.</Link>
                            </li>
                            <li>
                                <Link to='#'>Earth &amp; Environmental Sci.</Link>
                            </li>
                            <li>
                                <Link to='#'>Engineering</Link>
                            </li>
                            <li>
                                <Link to='#'>Medicine &amp; Healthcare</Link>
                            </li>
                            <li>
                                <Link to='#'>Physics &amp; Mathematics</Link>
                            </li>

                            <li>
                                <Link to='#'>Agriculture &amp; Food Science</Link>
                            </li>
                            <li>
                                <Link to='#'>Arts &amp; Literature</Link>
                            </li>
                            <li>
                                <Link to='#'>Education &amp; Language</Link>
                            </li>
                            <li>
                                <Link to='#'>Energy  &amp; Power</Link>
                            </li>
                            <li>
                                <Link to='#'>Law  &amp; Politics</Link>
                            </li>
                            <li>
                                <Link to='#'>Philosophy  &amp; Religion</Link>
                            </li>
                            <li>
                                <Link to='#'>Psychology  &amp; Behavioral Science</Link>
                            </li>
                            <li>
                                <Link to='#'>Sports  &amp; Leisure</Link>
                            </li>
                        </ul> */}


                        </div>
                        <div className='mt-md-0 mt-4 col-md-9' >
                            {/* <div className=' p-3 border mb-3 rounded' style={{ background: '#FFFFFF', position: 'sticky', top: '80px' }}>
                            <form onSubmit={handleSubmit}>
                                <input type="text" className='p-1 rounded' style={{ width: '90%' }} value={inputValue} onChange={handleInputChange} />
                                <button className='p-1 rounded px-2' type="submit">Submit</button>
                            </form>
                            <div className='my-1 pt-2'>
                                <input type='checkbox' className='me-2' id='local' name='local' />
                                <label className='me-2' htmlFor='local'>Local Journal</label>
                                <input className='me-2' type='checkbox' id='international' name='international' />
                                <label htmlFor='international'>International Journal</label>
                            </div>
                        </div> */}
                            <div className=' p-3 border rounded' style={{ background: '#FFFFFF' }}>
                                <a href="#">Creative Education / {selectedJournal?.volume}, {selectedJournal?.publicationDate}</a>
                                <div className='mb-2' style={{ background: 'gray', border: '2px solid gray' }}></div>
                                <h5>{selectedJournal?.title}</h5>
                                <p>{selectedJournal?.author?.map(author => {
                                    const nameParts = author.split(' ');
                                    const capitalizedParts = nameParts.map(part => part.charAt(0).toUpperCase() + part.slice(1));
                                    return capitalizedParts.join(' ');
                                }).join(", ")}</p>
                                {/* <a href="#">Department of Foundation of Education, Faculty of Education, Damietta University, Damietta, Egypt.</a> */}
                                <a href="#">DOI: {selectedJournal?.doi}   {selectedJournal?.download?.length} Downloads   {selectedJournal?.read?.length} Views </a>
                                <p>Categories: {selectedJournal?.category?.join(", ")}</p>
                                <h5 className='pt-2'>Keywords</h5>
                                <a href="#">{selectedJournal?.keyword?.join(", ")}</a>
                                <h5 className='pt-2'>Description</h5>

                                <div style={{ backgroundColor: '#F8F8F8 !important' }} dangerouslySetInnerHTML={{ __html: selectedJournal?.description?.replace(/style="[^"]*"/g, '') }} ></div>

                                <h5 className='pt-2'>Abstract</h5>
                                <div style={{ backgroundColor: '#F8F8F8 !important' }} className='pt-1' dangerouslySetInnerHTML={{ __html: selectedJournal?.abstract?.replace(/style="[^"]*"/g, '') }} ></div>


                                <h5 className="pt-2">1. Introduction</h5>
                                <div className='mb-2' style={{ background: 'gray', border: '2px solid gray' }}></div>
                                <div style={{ backgroundColor: '#F8F8F8 !important' }} className='pt-1' dangerouslySetInnerHTML={{ __html: selectedJournal?.introduction?.replace(/style="[^"]*"/g, '') }} ></div>

                                {/* <p>Educational research, its principles and applied methods are considered a main core in the development of human resources with its various expectations and goals. It is an accurate, organized and critical attempt which aims at reaching solutions for the problems that face man in different fields of life and adding facts and information to what is accumulated in the field of knowledge by using the scientific method in thinking. Educational research is produced by curiosity and is nourished on the deep interest in knowing the truth and improving the means with which various issues can be solved. Educational research is considered a way of a new demonstration of an old problem; or it is a modern awareness of a new problem related to a specific condition of the phenomenon or the event of interest.
                                <br />
                                <br />
                                “Being one of the fields of scientific research, educational research is concerned with processing educational issues and problems for the purposes of finding possible and proper solutions for these problems. It also contributes to formulating the educational policy, providing required information and data of making its decision, paving the way to changes and renewing processes, and enriching knowledge as well as employing it to solve problems” (Shara & Zoghbi, 2011: p. 1401) .
                                <br />
                                <br />
                                The importance of educational research lies in that “it both gives us a different perspective about education and the educational process. Moreover, it generates new ideas about dealing with educational problems” (Zaiton, 2004: p. 17) . It also provides a comprehensive analysis of the educational system conditions in order to identify its points of weakness and strength, the political, human and financial restrictions hindering its development, and the surrounding variables that can be controlled.
                                <br />
                                <br />
                                Hence, both developing and developed countries give due care to educational research for it depends on scientific methods in solving educational problems and taking convenient decisions in the light of these methods. It is also used to detect new knowledge and propose solutions, responses and alternatives which help us deepen our understanding of various educational dimensions together with their problems and unknown fields.
                                <br />
                                <br />
                                Moreover, scientific research as well as its different types has become more significant in recent years as it is one of the pivotal standards in the worldwide ranking of universities as it gives an important value for universities. For example, concentrates on what Shanghai Jiao Tong University in China produces in the field of scientific research. This scientific side obtains 60% of the total evaluation of the academic institution represented in the index of published articles in the two journals: “Nature and Science”. The existed articles in the Science Citation Index (SCI) and the Social Sciences Citation Index (SSCI) get 20% of the total degree of ranking. Moreover, Highly Cited Researchers Index (HiCi) in twenty one scientific specializations reaches 20% (Shanghai Ranking, 2017) .
                                <br />
                                <br />
                                The Times Higher Education World University Ranking assigns 30% of its evaluation to the rate of publication of every staff member in the related standard of citations and the effectiveness of published researches. This standard comes in the third place in ranking as an independent one. However, it obtains the most relative weight compared to other standards.
                                <br />
                                <br />
                                QS World University Ranking puts a standard related to citations which aims at evaluating the results of university researches through measuring how many times they are cited. This standard is given 20% and comes in the fourth place as an independent one. It is noticed here that QS ranking gives the second importance to international publication of evaluating universities, after the standard of international reputation which is given the first importance.
                                <br />
                                <br />
                                Ranking Web of World Universities sets a particular standard of research distinction which includes the number of published scientific papers in world journals of high impact. This standard is assigned an estimated weight of 15%.
                                <br />
                                <br />
                                This enables universities to compete with each other to reach an advanced value of this standard and to surpass the field of universal awards whose main factor is scientific research. The process of financing research projects nowadays depends on the number of the international researches published by are searcher, research groups and centers in universities. Hence, the significance of international publication lies in its great effect on the classification of universities and their status at the global level. Besides, scientific publication affects the efficiency of researchers since it contributes to developing the scientific abilities of researchers and strengthening scientific connections among researchers both locally and universally. Moreover, it enables the researcher to know points of strength and weakness in his/her researches through communicating with the participant researchers in the research or through peer reviewing the research during its publication in one of the eminent periodicals (Hafezy & Tabina, 2015) .
                                <br />
                                <br />
                                This gives thoughtful protection and privacy to that outcome and hence its scientific profit. Journals and periodicals, which form the main station for these researches, extend in a large scale and go to wide horizons of progress and development, thanks to the power of globalization, competition, development, information and knowledge progress. Therefore, researchers try to widen the distance of their scientific publication outcome to wider international prospects. However, the Egyptian educational research remains narrow-spread internationally and wide-spread locally on account of many scientific, financial, executive and personal hindrances. These hindrances are connected to information and related to publication. The Egyptian educational research is still limited, whereas the current globalization environment gives wider prospects to the spread of scientific research in general.
                                <br />
                                <br />
                                The issue of international publication has recently gained great interest in the various Egyptian universities. This is because of the reduction of the scientific publication index in general and the educational publication in particular in worldwide journals of impact factor in comparison with many other countries. Meanwhile, international publication becomes a principal production of good scientific research that has great importance at universities and research centers all over the world. Thus, the actual as well as the most crucial significance of international publication is that the outcome of researches should reach all specialized researchers and scientists in that branch of knowledge.
                                <br />
                                <br />
                                Although professors are mainly researchers who have both high experience and efficiency, they also face hindrances in publishing educational researches in international journals. As a result, it becomes necessary to identify these difficulties in order to overcome them in the future.</p>
                            <h5 className="pt-2">2. International Publication and Academic Distinction</h5>
                            <div className='mb-2' style={{ background: 'gray', border: '2px solid gray' }}></div>
                            <p>
                                “The equability of the scientific research in every field of knowledge lies in the amount of published researches in prestigious and well-known periodicals and journals that are recognized by worldwide ranking institutions. It also depends on the importance and effectiveness of these researches through the number of citations taken from them and how many professors and researchers refer to them in their researches. Both publishing researches and its spread abroad are considered one of the indicators used by international university ranking organizations” (Kodsy et al., 2011: pp. 80-81) .
                                <br />
                                <br />
                                The scientific competition in the field of research publication in well-known world journals increases in last years owing to the big competition among professors and researchers who seek and work hard to publish in these distinguished journals in order to get advanced positions as well as a scientific equability for their universities and a scientific reputation for themselves. Therefore, owing to both the strong competition and globalization, the rate of published researches in these distinguished journals is low, compared to the number of researches that are submitted to these journals.
                                <br />
                                <br />
                                Yet, reviewing these journals, it is noticed that the names of Egyptian researchers are very limited in sciences in general and in educational sciences in particular. This poses obvious questions about the ability of professors and researchers in educational faculties at Egyptian universities to enter the universal publication market. This responsibility lies on the shoulders of both Egyptian universities and their teaching staff members.
                                <br />
                                <br />
                                “The last decades have witnessed a stunning development in scientific publication techniques and instruments. Such techniques solve many difficulties that hinder the process of publication. This was accompanied by unprecedented emphasis by editors in chief of scientific journals lest the level of their journals should decline. The impact factor, issued by journal citation reports, becomes an obsession to the editing organizations lest the level of journals they manage should decline in case quoting their researches is reduced”.
                                <br />
                                <br />
                                Universities always seek to achieve distinction among other competing universities so as to get benefit from the standards of its universal ranking which concentrates on achieving enrichment in the field of scientific research. Shanghai ranking, one of the world rankings of universities.
                                <br />
                                <br />
                                Although the forces of globalization, including academic globalization, have achieved communication and shortening distances among university professors and researchers of different countries, the Egyptian scientific research, especially the educational one, is still surrounded by many hindrances that make it adhere to being locally-oriented; it does not arise to the universal academic space and academic publication. In addition, Egyptian universities ranking is low because international publication in Egypt is very poor. Therefore, the current study aims to analyze and examine the hindrances that reduce the international publication of Egyptian educational researches according to the teaching staff members’ points of view at Damietta University. It also tries to find out solutions and answers which can contribute to reducing the impacts of such hindrances that negatively affect the expansion of Egyptian educational research abroad.
                            </p>
                            <h5 className="pt-2">3. Damietta University and International Publication</h5>
                            <div className='mb-2' style={{ background: 'gray', border: '2px solid gray' }}></div>
                            <p>
                                Damietta University is considered one of the newly established universities in Egypt; it started as a branch of Mansoura University owing to the increasing number of students who come from Damietta to join different faculties at Mansoura University. Hence, the decisions of establishing Damietta branch were introduced to establish faculties similar to the ones at Mansoura University. Therefore, working at the faculty of education started in the academic year 1976-1977, followed by working at faculties of science and commerce in 1985-1986, then the faculty of specific education in 1990-1991, faculty of applied arts in 2004-2005, and finally faculties of agriculture, arts, and physical education in 2006-2007. The republic Decision no. 19 was issued in July 2012 to establish Damietta University in New Damietta city (Self-Study of Damietta University 2014-2015, 2017: p. 13) .
                                <br /><br />
                                Currently, Damietta University is composed of eight faculties: faculty of education, faculty of specific education, faculty of physical education, faculty of applied arts, faculty of agriculture, faculty of science, faculty of commerce and faculty of arts. The number of professors at the university is 143; 21 of them are in educational faculties, representing 14.7% whereas the assistant professors at the university are 143; 43 of them are in educational faculties, representing 30.5%. Moreover, the number of lecturers reaches 287; 97 of them are in educational faculties, representing 33.8%. The number of registered students at the university is 23,602; 9295 of them are in educational faculties, representing 39.4%. Therefore, the average of students to the number of the teaching staff in educational faculties is 57.7 students per each member of staff. This average is bigger than the one existed in human and social specializations in many universities which have advanced positions in world rankings.
                                <br /><br />
                                The self-study of Damietta University (2014-2015) indicates that the number of internationally published researches of the teaching staff members is 891; 36 of them belong to educational faculties with the average of 4% only. As for the local publication, the number of published researches is 2138 researches; 638 of them are educational ones, representing 29.8% (p. 151).
                                <br /><br />
                                The previous data indicates the shortage of international publication in all faculties of the university in comparison with the local one, especially in educational faculties. The average of international publication in these faculties reaches 6%, compared to the local one. Hence, it is essential to identify the hindrances so as to solve them in the future.
                            </p>
                            <h5 className="pt-2">4. The Importance of the Current Study</h5>
                            <div className='mb-2' style={{ background: 'gray', border: '2px solid gray' }}></div>
                            <p>
                                The importance of the current research lies in the following points:
                                <br /><br />
                                - The current research concentrates on one of the important sides in societies nowadays, namely international publication of scientific researches, particularly the educational ones that form an essential foundation for achieving the sustained progress and development.
                                <br /><br />
                                - The current research covers an important sector, namely the academic sector that contributes to developing all other sectors in society.
                                <br /><br />
                                - This topic is a vital element in the development and progress of countries since we cannot imagine a society without scientific research in general, and educational research in particular. That is because current life is characterized by complication, entanglement, development and the appearance of modern terms that were not found before.
                                <br /><br />
                                - Universities today are seriously seeking to increase the quality and the rate of the international publication of their teaching staff members in order to get advanced world ranking that scientific research forms one of its important standards.
                            </p>
                            <h5 className="pt-2">5. Literature Review</h5>
                            <div className='mb-2' style={{ background: 'gray', border: '2px solid gray' }}></div>
                            <p>
                                Many studies all over the world tackled the difficulties that hinder academic research and publication in world journals. Some of these studies concentrated on the difficulties that face post graduate students; others shed light on the ones that hinder professors. Cheung (2010) , for example, discussed the difficulties which PhD students in Hong Kong face regarding international publication. On the other hand, Cho’s study (2004) focused on the challenges that face PhD students who are non-native speakers of English in publishing in world journals. Huang (2010) also investigated the challenges that PhD students suffer from in Taiwan. Studies such as Memarpour et al. (2015) investigated the obstacles that students of medical sciences in Iran encountered in academic researches. Alsayed et al. (2012) studied the difficulties that met post graduate students in Saudi Arabia in publishing their researches. In fact, there are many studies such as those of Flowerdew (1999) , Lillis & Curry (2006) , Hyland (2011) and Hyland (2009) which concentrated on the problems that face researchers, especially those who are non-native speakers of English when they write their researches in English, which is widely recognized as an international language of world publication.
                                <br /><br />
                                Several academicians from Asia, Africa, Europe and even Canada investigated the ways of facing challenges in the field of research and academic publication in world journals. Flowerdew (1999) , for instance, found through making interviews with academicians of different specializations in Hong Kong that language represents their main obstacle in world publication. On the other hand, Tahir & Baker (2009) found that the main hindrances for professors in Malaysia regarding international publication are their inability to master statistical techniques and writing skills. Alzahrani (2011) mentioned that the main problem that faces the teaching staff members of various specializations in Saudi Arabia is the difficulty of getting the scientific material. Omer (2015) found that lack of both English language originality of research topics are the most important hindrances for all the teaching staff members at Najran University in Saudi Arabia regarding publication in the journals of Information Scientific Institutions (ISI).
                                <br /><br />
                                In addition, Nguyen & Klopper (2014) and Bauer (2011) studied the effects of Vietnam national politics and the cultural and political barriers on both production and research publication of Vietnamese scientists in different fields of research. Even in developing African countries like Zimbabwe, Garwe (2015) found that the main hindrance of the field of research and international publication lies in the shortage of institutional financing. In Namibia, weakness of research, academic writing skills and the weakness of financing international publication are considered the main difficulties (Lehto et al., 2012) .
                                <br /><br />
                                In Europe, Burgess et al. (2014) and Gea-Valor et al. (2014) found that Spanish scientists of social sciences face problems related to language which also forms a main hindrance to researchers in medicine in Spanish institutions besides problems of English academic writing. Language also is a problem for Canadian researchers as Gentil & Seror (2014) stated.
                                <br /><br />
                                There are four hindrances that face researchers in scientific publication, particularly in world journals: 1) lack of interest in the well preparation researchers; 2) complicated procedures of publication; 3) scientific and financial obstacles of publication as the most important ones that teaching staff members face; and 4) deficiency in the scientific and self-skills of teaching staff members in finishing researches of high quality and their lack of the required knowledge to communicate with world scientific journals and periodicals, besides knowing the required standards of publication in these journals.
                                <br /><br />
                                It is noticed in these previous studies that most of them focused on the difficulties that face researchers in the fields of science and technology regarding international publication. On the other hand, there is a shortage in experimental studies that tackled the obstacles facing researchers in fields of social, human, and educational sciences, in particular, in publication in scientific journals of high impact factor particularly in the countries that use English as a foreign language. One of these studies is that done by Ge (2015) which handled the difficulties the Chinese academicians face in the fields of human and social sciences in international publication in English. These studies gave us a sharp view about the problems of non-native speakers of English in writing a research as well as academic publication in world journals.
                            </p>
                            <h5 className="pt-2">7. Data Analysis</h5>
                            <div className='mb-2' style={{ background: 'gray', border: '2px solid gray' }}></div>
                            <p>This section includes an analysis of the data related to questionnaires and interviews to pinpoint the hindrances of international publication faced by teaching staff members of educational faculties at Damietta University, especially in world journals of high impact factor. It also identifies the reasons behind the refusal of some of these journals to publish educational researches related to some of the teaching staff. Moreover, it discusses the reasons that stop some of them from continuing their publication of researches in world journals of high impact, although they have already managed to publish some researches abroad.</p>
                            <h5 className="pt-2">8. Findings</h5>
                            <div className='mb-2' style={{ background: 'gray', border: '2px solid gray' }}></div>
                            <p>
                                The results of this study are summarized under the following three axes, focusing on the teaching staff members of educational faculties at Damietta University: 1) reasons why some of them did not try to publish in world journals; 2) reasons why some educational researches of some teaching staff members at the university are rejected by world journals; and 3) reasons why some of them did not continue with international publication.
                                <br /><br />
                                1) Reasons of Why Some Staff Members Did Not Try to Publish in World Journals
                                <br /><br />
                                The results of the questionnaire analysis indicated that most of the teaching staff members did not try to publish their educational researches in world journals, as 67.4% of the participants confirmed. Female staff members represented 31.4% whereas male staff members represented 58.6% of the participants. More specifically, professors who did not try to publish in world journals represented 13.8%, whereas 31% of the assistant professors and 55.2% of the lecturers did not try world publication.
                                <br /><br />
                                The analysis of the reasons for not trying to publish in world journals indicated that 35.3% of male and 66.7% of female staff members did not try to publish their researches in world journals because they were unable to meet the language requirements of world journals, particularly those published in English. This is a high percentage that demonstrates the extent to which staff members of educational faculties lack the proper English writing style that is required by world journals.
                                <br /><br />
                                The previous analysis indicated that weakness in the writing style required by world journals was a stronger factor in females than in males. Consequently, this negatively affects the attempts to publish educational researches abroad.
                                <br /><br />
                                The results of the questionnaire also indicated that 29.4% of male teaching staff members confirmed that educational publication in world journals requires a long time of preparation, revision and waiting for the reply of the journal Similarly, 50% of the female teaching staff members affirmed the same reasons. It is worth noting that the percentage of female teaching staff was higher than that of the male considering this reason.
                                <br /><br />
                                Most of the questionnaire participants agree that the high cost of publication is considered one of the important reasons for not attempting to publish in these journals. 82.3% of the male and 50% of the female teaching staff, who did not try to publish abroad, confirmed that the high cost of publishing abroad is considered one of the main hindrances of publishing in world journals. It is here that the percentage of males overcomes that of females regarding the high cost of publication abroad (Figure 1).
                                <br /><br />
                                The results also showed that some staff members were not willing to publish their researches internationally because international publication was not a requirement for promotion. 23.5% of male and 41.6% of female teaching staff members confirmed this view.
                                <br /><br />
                                A large number of the teaching staff members who participated in the questionnaire affirmed that their ignorance of publication requirements and standards in world journals was one of the important reasons that prevent them from attempting to publish internationally. This view was asserted by 58.8% of male and 41.6% of female teaching staff members.
                            </p>
                            <img style={{ width: '100%' }} src="https://html.scirp.org/file/7-6303997x2.png" alt="" />
                            <p>
                                Figure 1. Reasons why some of the teaching staff members didn’t try to publish in world journals.
                                <br /><br />
                                On the other hand, the results of the questionnaire analysis showed that 29.4% of male teaching staff members, who did not try to publish abroad, found it difficult to cope up with the rules and standards of publication and the requirements of writing and documentation in world journals. 41.6% of female teaching staff stated that the strict rules of international publication were one of the basic hindrances of international publication, especially in prestigious specialized journals.
                                <br /><br />
                                A number of the questionnaire participants, who did not try to publish in world journals, affirmed the responsibility of the university towards the shortage of internationally published researches owing to the absence of a mechanism in the university which helps the teaching staff members to re-edit their educational researches with what suites the rules and standards in these journals. Therefore, 35.3% of male teaching staff confirmed this view regarding the absence of a mechanism that helps them to edit their researches or even helps them in communicating with some of these journals through common protocols between them and the university. On the other hand, a small number of female teaching staff members, 16.7%, affirmed that opinion.
                                <br /><br />
                                Some of the teaching staff confirmed through interviews that the absence of common protocols between the university and some of the international journals has a negative effect on the desire to publish educational researches abroad. In addition, the weakness in the academic financing regarding world publication has a negative effect on the percentage of the published researches in world journals.
                                <br /><br />
                                Some interviews showed that many of the teaching staff members tend to publish their researches locally due to its facilities, low cost and quickness of publication, compared to that of international publication. Some professors pointed out that teaching staff members tend to publish their researches locally because international publication the absence of a requirement of promotion.
                                <br /><br />
                                It is interesting that some of the teaching staff members who got their PhD from a foreign country did not try to publish abroad after their coming back home. They also confirmed the difficulty of publication as well as its high cost in comparison with the local one.
                                <br /><br />
                                Some professors and assistant professors affirmed that, due to the nature of their specializations which are based on Arabic language and its teaching methods, they do not need to publish their researchers internationally; they just need well-known Arab journals in these specializations.
                                <br /><br />
                                Some interviews indicated that limited income, especially in educational faculties does not suit the high cost of publishing researches in such journals. It is worth noting that some of the teaching staff confirmed through their interviews that they found difficulty in knowing the suitable world journals in their specializations, so they resorted to publish in local journals because they are easy to contact with and publish in.
                                <br /><br />
                                As a matter of fact, choosing the suitable journal to publish a research paper, especially in world journals, is not an easy task as these journals are characterized by strict rules and standards of publication. This also explains why most of the sample participants did not try to publish in these journals. The percentage increases to those who have no experience abroad.
                                <br /><br />
                                Some of the teaching staff pointed out that writing a research paper in English according to the requirements of international journals is one of the greatest hindrances that prevent international publication. That is because of the difficult standards of introducing data and analyzing it as well as the writing style and mastering English as the main language of publishing in international journals. Actually, this is not available for many of the teaching staff members, especially those who have no teaching experience abroad.
                                <br /><br />
                                2) Reasons Why Some Educational Researches Are Rejected by World Journals
                                <br /><br />
                                The second axis of the questionnaire shows the failed attempts of teaching staff members to publish in international journals as well as the reasons for rejecting these researches from the point of view of the journal referee committee. The responses regarding this axis were very limited, as only 4.7% of them affirmed that their attempts at international publication were refused by the journal because they did not commit to the specific standards of publication and documentation of the journal.
                                <br /><br />
                                Moreover, a member of the teaching staff pointed out that he did not give due care to the instructions of the journal and that he did not follow the referees’ report. Consequently his research was eventually rejected. Another staff member indicated that the reasons behind this rejection lay in the lack of coherence between research content and the methodology used. The few attempts at international publication as well as the rejection of these attempts indicated that teaching staff members at the faculties of education, Damietta University are not enthusiastic about international publication, in comparison to local publication.
                                <br /><br />
                                3) Reasons Why Some Damietta University Staff Members Did Not Continue with International Publication
                                <br /><br />
                                The third axis of the questionnaire dealt with the teaching staff members who were able to publish their educational researches in world journals, whether they continued publishing abroad, and the reasons behind their discontinuation. Results showed that 27.9% of the participants managed to publish in world journals. The percentage of the male teaching staff reached 83.3% whereas the female one reached 16.7% and this indicated the obvious superiority of the male members in international publication.
                                <br /><br />
                                Results also revealed the difference in the number of educational researches published by every member of the sample (see Figure 2). 58.3% of them managed to publish one research and they did not continue to publish due to the high cost of international publication and the difficulties of its procedures, though half of them got their PhD from English speaking countries like Britain and America.
                                <br /><br />
                                Results also showed the limited number of the teaching staff members who published more than one research. That is, the percentage of the teaching staff


                            </p>
                            <img style={{ width: '100%' }} src="https://html.scirp.org/file/7-6303997x3.png" alt="" />
                            <p>
                                Figure 2. Percentage of published researches in word journals by the sample members.
                                <br /><br />
                                members who managed to publish two international researches reached only 25%, whereas only 8.3% the teaching staff published three or four international researches.
                                <br /><br />
                                It noted that only those PhD holders from UK who managed to publish three or four international researches. That is because they can speak and write English better than others; they also have a direct contact with international journals during their study. This indicates their superiority among the other teaching staff members concerning the number of published researches.
                                <br /><br />
                                Thus, it is obvious that there is a strong and direct relationship between how much a teaching staff member keeps in touch with and studies in an English speaking country and his/her ability to publish educational researches in world journals. A member of the teaching staff affirmed that it is not an easy task to publish internationally because of the difficult requirements of these journals in writing, analyzing and introducing the research. But what helped him was his PhD study in England which made him master English writing skill and easily reach world journals.
                                <br /><br />
                                9. Conclusion
                                <br /><br />
                                Based on the quantitative and qualitative analysis of data on hindrances of international publication from the point of view of Damietta University teaching staff members, the results revealed that teaching staff members are less interested in publishing educational researches in world journals. Results also showed that many staff members, who had previous experience in international publication, do not continue publishing in these journals, preferring publishing in local journals, instead, owing to both the difficulty and high cost of international publication.
                                <br /><br />
                                In addition, results showed that there is a positive relationship between getting PhD from a foreign country, especially an English speaking one, and a staff member’s interest and success in publishing researches in world journals. Concerning hindrances that prevent teaching staff members from publishing educational researches in world journals, the study found that the high cost of publishing and poor financing are the most significant hindrances that prevent publishing in these journals. Lack of knowledge of publication standards, the difficulty to find suitable and specialized journals, poor writing style, and imperfection of writing skills in English represent hindrances for many staff members. Consequently, this negatively affects staff members’ interest in world journals.
                                <br /><br />
                                Results also pointed out that the difficulty of publication standards in world journals if is compared to the easy ones in local publishing had a great effect on the tendency of the teaching staff members to publish locally rather than internationally. Some of the teaching staff members referred to the heavy and administrative burdens that affected greatly their possibility to complete their researches with what suits the strict rules of recognized world journals with the absence of a mechanism that helps them to re-edit their researches according to the publication rules of these journals.
                                <br /><br />
                                The current study found out that many teaching staff members at Damietta University ignore international publication particularly at educational faculties because international publication is not a requirement for promotion. Consequently, those staff members turn to local publication which is characterized simplicity, low cost, and quickness of response in comparison with the difficulty of publishing in world journals.
                            </p>
                            <h5 className="pt-2">Funding</h5>
                            <div className='mb-2' style={{ background: 'gray', border: '2px solid gray' }}></div>
                            <p>
                                The author(s) received no financial support for the research and/or authorship of this article.
                                <br></br><br></br>
                                Conflicts of Interest
                                <br></br><br></br>
                                The authors declare no conflicts of interest.
                                <br></br><br></br>
                                References
                                <br></br><br></br>
                                [1]	Alsayed, N., Eldeek, B., Tayeb, S., Ayuob, N., & Al-Harbi, A. (2012). Research Practices and Publication Obstacles among Interns at King Abdullaziz University Hospital, Jeddah, Saudi Arabia, 2011-2012. Journal of the Egyptian Public Health Association, 87, 64-70.
                                https://doi.org/10.1097/01.EPX.0000417978.44502.61 <br></br><br></br>
                                [2]	Alzahrani, J. A. (2011). Overcoming Barriers to Improve Research Productivity in Saudi Arabia. International Journal of Business and Social Science, 2, 50-57.
                                <br></br><br></br>  [3]	Bauer, T. (2011). The Challenge of Knowledge Sharing: Practices of the Vietnamese Science Community in Ho Chi Minh City and the Mekong Delta. LIT Verlag: Zurich, Switzerland.
                                <br></br><br></br>  [4]	Burgess, S., Gea-Valor, M. L., Moreno, A. I., & Rey-Rocha, J. (2014). Affordances and Constraints on Research Publication: A Comparative Study of the Language Choices of Spanish Historians and Psychologists. Journal of English for Academic Purposes, 14, 72-83.
                                https://doi.org/10.1016/j.jeap.2014.01.001 <br></br><br></br>
                                [5]	Cheung, Y. L. (2010). First Publications in Refereed English Journals: Difficulties, Coping Strategies, and Recommendations for Student Training. System, 38, 134-141.
                                https://doi.org/10.1016/j.system.2009.12.012 <br></br><br></br>
                                [6]	Cho, S. (2004). Challenges of Entering Discourse Communities through Publishing in English: Perspectives of Nonnative-Speaking Doctoral Students in the United States of America. Journal of Language Identity & Education, 3, 47-72.
                                https://doi.org/10.1207/s15327701jlie0301_3 <br></br><br></br>
                                [7]	Flowerdew, J. (1999). Problems in Writing for Scholarly Publication in English: The Case of Hong Kong. Journal of Second Language Writing, 8, 243-264.
                                https://doi.org/10.1016/S1060-3743(99)80116-7 <br></br><br></br>
                                [8]	Garwe, E. C. (2015). Obstacles to Research and Publication in Zimbabwean Higher Education Institutions: A Case Study of the Research and Intellectual Expo. International Research in Education, 3, 119-138.
                                https://doi.org/10.5296/ire.v3i1.7009 <br></br><br></br>
                                [9]	Ge, M. (2015). English Writing for International Publication in the Age of Globalization: Practices and Perceptions of Mainland Chinese Academics in the Humanities and Social Sciences. Publications, 3, 43-64.
                                https://doi.org/10.3390/publications3020043 <br></br><br></br>
                                [10]	Gea-Valor, M.-L., Rey-Rocha, J., & Moreno, A. I. (2014). Publishing Research in the International Context: An Analysis of Spanish Scholars’ Academic Writing Needs in the Social Sciences. ELSEVIER, 36, 47-59.
                                https://doi.org/10.1016/j.esp.2014.05.001 <br></br><br></br>
                                [11]	Gentil, G., & Seror, J. (2014). Canada Has Two Official Languages—Or Does It? Case Studies of Canadian Scholars’ Language Choices and Practices in Disseminating Knowledge. Journal of English for Academic Purposes, 13, 17-30.
                                https://doi.org/10.1016/j.jeap.2013.10.005 <br></br><br></br>
                                [12]	Hafezy, N., & Tabina. R. (2015). Scientific Publishing between Importance and Realistic Difficulties. Algeria: Scientific Research Consolidation Forum.
                                [13]	Huang, J. C. (2010). Publishing and Learn Writing for Publication in English: Perspectives of NNES PhD Students in Science. Journal of English for Academic Purposes, 9, 33-44.
                                https://doi.org/10.1016/j.jeap.2009.10.001 <br></br><br></br>
                                [14]	Hyland, K. (2009). English for Professional Academic Purposes: Writing for Scholarly Publication. In D. Belchor (Ed.), English for Specific Purposes in Theory and Practice (pp. 83-105). University of Michigan Press: Ann Arbor, MI, USA.
                                [15]	Hyland, K. (2011). Welcome to the Machine: Thoughts on Writing for Scholarly Publication. Journal of Second Language Teaching & Research, 1, 58-68.
                                https://doi.org/10.5420/jsltr.01.01.3319 <br></br><br></br>
                                [16]	Kodsy, Y., Osman, I., & Halak, R. (2011). Describing the Institutional Evaluation to World Scientific Periodicals. Journal of Damascus, University of Main Sciences, 27, 71-84.
                                [17]	Lehto, A., Matangira, V., Shatona, M., & Kahengua, K. (2012). Obstacles to Scholarly Publishing by Academic Librarians. In M. Livonen, P. Helminen, J. Ndinoshiho, & O. Sisatto (Eds.), Empowering People: Collaboration between Finnish and Namibian University Libraries (pp. 270-291). Tampere: Tampere University Press.
                                [18]	Lillis, T., & Curry, M. J. (2006). Professional Academic Writing by Multilingual Scholars: Interactions with Literary Brokers in the Production of English-Medium Texts. Written Communication, 23, 3-35.
                                https://doi.org/10.1177/0741088305283754 <br></br><br></br>
                                [19]	Memarpour, M., Fard, A. P., & Ghasemi, R. (2015). Evaluation of Attitude to, Knowledge of and Barriers toward Research among Medical Science Students. Asia Pacific Family Medicine, 14, 1-7.
                                https://doi.org/10.1186/s12930-015-0019-2 <br></br><br></br>
                                [20]	Merriam, S. B. (2009). Qualitative Research: A Guide to Design and Implementation. San Francisco, CA: Jossey-Bass.
                                <br></br><br></br>  [21]	Nguyen, Q. H., & Klopper, C. J. (2014). The Influences of Research Environment within a University on Research Productivity of Academic Staff—A Case Study in a Research-Oriented University in Vietnam. International Journal of Arts and Sciences, 7, 189-197.
                                <br></br><br></br>  [22]	Omer, R. A. (2015). International Scientific Publication in ISI Journals: Chances and Obstacles. World Journal of Education, 5, 81-90.
                                https://doi.org/10.5430/wje.v5n6p81 <br></br><br></br>
                                [23]	Self-Study of Damietta University 2014-2015 (2017). <br></br><br></br>
                                http://www.du.edu.eg/upfilescenter/uni/1453207878.pdf <br></br><br></br>
                                [24]	Shanghai Ranking (2017). <br></br><br></br>
                                http://www.shanghairanking.com/ARWU2016.html <br></br><br></br>
                                [25]	Shara, I., & Zoghbi, T. (2011). Problems of Educational Research from the Teaching Staff Point of View of Education Science Faculties at National Jordan Universities. Journal of Education Science, Jordan University, 38, 1393-1409.
                                <br></br><br></br>  [26]	Tahir, I. M., & Baker, N. M. A. (2009). An Evaluation of Lecturers’ Perceptions towards Research. Social Sciences, 4, 416-423.
                                <br></br><br></br>  [27]	Zaiton, K. (2004). Methods of Psychological and Educational Research from the Quantitative and Qualitative Perspectives. Cairo: Book World.
                            </p> */}
                            </div>
                        </div>

                    </div>
                </Container>
                </div>
            ) : (
                <div className={`${isNightMode ? 'nightJournalDetail' : ''} mt-5 pt-5`} style={{ width: '90%', margin: 'auto' }}>
                    <button className='btn rounded-pill btn-primary mb-1' style={{ padding: '.4rem 1rem' }} onClick={() => setPDFOpen(false)}><ArrowLeftCircle /> Back</button>
                    <div style={{ overflowX: 'scroll', overflowY: 'none' }}>
                        <object
                            // data={resUrl}
                            data={`http://nmdc-library.s3.amazonaws.com/journalPdf/${selectedJournal?.pdf}`}
                            type="application/pdf"
                            width="100%"
                            style={{ height: '100vh' }}
                        >
                            <p>PDF viewer not supported in this browser. Please download the PDF file to view it: <a href="https://aptmbeta.s3.ap-southeast-1.amazonaws.com/pdf/37ad3d0a-1184-4764-a48d-0360a9890031.pdf">Download PDF</a></p>
                        </object>
                    </div>

                </div>
            )}

        </div>
    );
};

export default JournalsDetailUpdate;

