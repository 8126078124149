import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import './style.css'
import { ArrowLeftCircle, X, XCircle } from 'react-feather';
import PdfViewer from './PdfViewer';
import { useGlobalContext } from '../../context';
import PreloadPdf from './PreloadPdf';

// import FlexPaperViewer from 'flexpaper-react';


const BookDetail = () => {
    const { id } = useParams();
    const [book, setBook] = useState({});
    const [PDFOpen, setPDFOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate();

    const { isNightMode } = useGlobalContext();

    const handleGoBack = () => {
        // window.history.back('../');
        const location = window.location?.href
        console.log(location?.split("/"))
        if (location?.split('/')[3] === 'popularAuthors') {
            navigate(`../${location?.split("/")[3]}/${location?.split("/")[4]}`);
        } else if (location?.split("/")?.length > 4) {
            navigate(`../${location?.split("/")[3]}`);
        } else {
            navigate(`../`);
        }
    };
    console.log(book?.title)

    // {
    //     id: 1,
    //     title: 'To Kill a Mockingbird',
    //     author: 'Harper Lee',
    //     // coverPhoto: 'https://images.penguinrandomhouse.com/cover/9780446310789',
    //     coverPhoto: 'https://cdn.britannica.com/21/182021-050-666DB6B1/book-cover-To-Kill-a-Mockingbird-many-1961.jpg',
    //     description: 'The story takes place during three years (1933–35) of the Great Depression in the fictional "Maycomb County" of Alabama, USA. It is told by six-year-old Jean Louise Finch, known as Scout, who lives with her older brother Jem and their widowed father Atticus, a middle-aged lawyer. Jem and Scout befriend a boy named Dill who visits Maycomb to stay with his aunt each summer. The three children are terrified yet fascinated by their neighbor, the reclusive Arthur \"Boo\" Radley.',
    //     isbn: '9780446310789',
    //     pages: 336,
    //     publicationDate: 'July 5, 1960',
    //     type: 'Fiction',
    //     level: 'Intermediate',
    //     rating: 4.5
    // }


    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const response = await fetch(`https://example.com/api/books/${id}`);
    //             const data = await response.json();
    //             setBook(data);
    //         } catch (error) {
    //             console.error(error);
    //         }
    //     };

    //     fetchData();
    // }, [id]);

    // if (!book) {
    //     return <p>Loading...</p>;
    // }
    useEffect(() => {
        fetch(`https://library-b.ethical-digit.com/books/${id}`)
            // fetch(`https://library-b.ethical-digit.com/books`)
            .then(response => response.json())
            .then(res => {
                if (res?.book) {
                    console.log(res)
                    setBook(res?.book)
                    setLoading(false)
                } else {
                    console.log(res)

                }


            })
            .catch(error => console.error(error));
    }, [])

    const readCount = () => {
        fetch(`https://library-b.ethical-digit.com/books/${id}/read`,{
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
        })
            // fetch(`https://library-b.ethical-digit.com/books`)
            .then(response => response.json())
            .then(res => {
                console.log(res)
            })
            .catch(error => console.error(error));
    }

    useEffect(() => {
        const elementsWithAriaLabelDownload = document.querySelectorAll('[aria-label="Download"]');
        console.log(elementsWithAriaLabelDownload);
        elementsWithAriaLabelDownload.forEach((element) => {
          element.style.display = 'none';
        });
    },[book?.pdf])


    return (
        <>
            {loading ? (
                <div style={{ width: '100vw', height: '100vh', backgroundSize: 'cover', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                    <img className="pt-4 mt-4" style={{ width: '20vw' }} src="https://cdn.dribbble.com/users/337865/screenshots/3209990/book-loader_v1.4_transp_800x600.gif" alt="" />
                </div>
            ) : (
                !PDFOpen ? (
                    book && (
                        <div className={`${isNightMode ? 'nightBookDetail' : ''}`} style={{background: '#ECF0F3'}}>
                            <PreloadPdf pdfUrls={
                                [
                                    `http://nmdc-library.s3.amazonaws.com/pdf/${book?.pdf}`,
                                    // Add more PDF URLs as needed
                                ]
                                } />
                        <Container className={`mt-5 pt-5 ${isNightMode ? 'nightBookDetail' : ''}`} style={{ minHeight: '86vh', background: '#ECF0F3' }}>
                            <Row>
                                <Col md={3} >
                                    {/* <Card className='no-border' style={{ border: 'none !important' }}> */}
                                    <Card.Img variant="top" src={`http://nmdc-library.s3.amazonaws.com/pdf/images/${book?.coverPhoto}` || 'https://images.penguinrandomhouse.com/cover/9780446310789'} style={{ width: '100%', height: 'auto', objectFit: 'cover', maxHeight: '400px', borderRadius: '5px' }} />
                                    <Button style={{ width: '100%', borderRadius: '5px' }} className='mt-2' variant="primary" onClick={() => {setPDFOpen(true); readCount();}}>Read Now</Button>
                                    {/* </Card> */}
                                </Col>
                                <Col md={9} className='relative mt-2'>
                                    <Card>
                                        <Card.Body>
                                            <h2>{book?.title}</h2>
                                            <Card.Subtitle className="mb-2 text-muted">by  {book?.author ? book?.author.map(author => author.name).join(', ') : "Not known"}</Card.Subtitle>
                                            <Card.Title>About the Book</Card.Title>
                                            <Card.Text className='mb-1'>
                                                <span className='font-weight-bold'>Publication Date </span>: {book?.publicationDate}
                                            </Card.Text>
                                            <Card.Text className='mb-1'>
                                                <span className='font-weight-bold'>Type </span>: {book?.type}
                                            </Card.Text>
                                            <Card.Text className='mb-1'>
                                                <span className='font-weight-bold'>Level </span>: {book?.level}
                                            </Card.Text>
                                            <Card.Text className='mb-1'>
                                                <span className='font-weight-bold'>Rating </span>: {book?.rate}/5
                                            </Card.Text>
                                            <Card.Text className='m-1'>{book?.description}</Card.Text>
                                            <Button onClick={handleGoBack} variant="link"><XCircle style={{ width: '40px', height: '40px', position: 'absolute', top: '9', right: '9' }} /></Button>

                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>

                        </Container>
                        </div>
                    )
                ) : (
                    <div className='pt-5 px-lg-5 px-1' style={{ marginTop: '2rem' }}>
                        <button className='btn rounded-pill btn-primary mb-1' style={{ padding: '.4rem 1rem' }} onClick={() => setPDFOpen(false)}><ArrowLeftCircle /> Back</button>
                        <div className='laptopSizePdfViewer' style={{ overflowX: 'scroll', overflowY: 'none', height: '100vh' }}>
                            {/* <object
                                data={`http://nmdc-library.s3.amazonaws.com/pdf/${book?.pdf}`}
                                type="application/pdf"
                                width="100%"
                                style={{ height: '100vh' }}
                            >
                                <p>PDF viewer not supported in this browser. Please download the PDF file to view it: <a href="" download="">Download PDF</a></p>
                            </object> */}
                            {/* <PdfViewer URL={`http://nmdc-library.s3.amazonaws.com/pdf/${book?.pdf}`} /> */}
                            <PdfViewer URL={book?.pdf} />
                            {/* <iframe src="https://aptmbeta.s3.ap-southeast-1.amazonaws.com/pdf/94f54364-5384-4f78-958a-23dd7b41b5e5.pdf" width="100%" height="100%" seamless></iframe> */}

                            {/* <FlexPaperViewer document={`https://aptmbeta.s3.ap-southeast-1.amazonaws.com/pdf/94f54364-5384-4f78-958a-23dd7b41b5e5.pdf`} /> */}


                            {/* {error && <p>Failed to load PDF: {error.message}</p>} */}
                        </div>
                        <div className='phoneSizePdfViewer' style={{ width: '100%', height: '750px' }}>
                            <PdfViewer URL={book?.pdf} />
                        </div>
                    </div>
                )
            )}
        </>
    );
};

export default BookDetail;
